<div class="row no-padding responsive">
  <div class="col">
    <img class="col-xs-6 xs-offset-3 mx-auto d-block" src="assets/sustain.jpg">
  </div>
</div>

<div class="row no-padding responsive">
  <div class="col">
    <img class="col-xs-6 xs-offset-3 mx-auto d-block" src="assets/sustain-no-prices.jpg">
  </div>
</div>

<div class="row no-padding">
  <div class="col">
    <p class="lists">
      <b>Consulta qui <a routerLink="/list/A">Lista A</a> e <a routerLink="/list/B">Lista B</a></b>
    </p>
  </div>
</div>

<div class="row no-padding div-custom-link">
  <div class="col">
    <a href="assets/RegolamentoPrestitoGiochi.pdf" class="custom-link" download>Scarica il regolamento del prestito in PDF</a>
  </div>
</div>
