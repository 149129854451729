import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catan',
  templateUrl: './catan.component.html',
  styleUrls: ['./catan.component.less']
})
export class CatanComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
