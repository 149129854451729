<div class="row no-padding responsive">
  <div class="col">
    <img class="col-xs-6 xs-offset-3 mx-auto d-block" src="assets/catan2024.jpg">
  </div>
</div>
<div class="row no-padding div-custom-link">
  <div class="col">
    <a class="custom-link" target="_blank" href="/assets/Regolamento_Catan_JP_20240317.pdf"><b>Ecco il regolamento!</b></a>
    <br>
    <a class="custom-link" [routerLink]="['/home']"><b>Home</b></a>
  </div>
</div>
