<!--<div class="row no-padding responsive">
  <div class="col">
    <img class="col-xs-6 xs-offset-3 mx-auto d-block" src="assets/photo_2023-04-08_12-40-47.jpg">
  </div>
</div>
<div class="row no-padding div-custom-link">
  <div class="col">
    <a class="custom-link" target="_blank" href="/assets/Regolamento_Catan_JP_20230417.pdf"><b>Ecco il regolamento!</b></a>
  </div>
</div>

-->
<div class="row no-padding responsive">
  <div class="col">
    <img class="col-xs-6 xs-offset-3 mx-auto d-block" src="assets/SostegnoAd.jpg" routerLink="/sustain">
  </div>
</div>

<div class="row no-padding div-custom-link">
  <div class="col">
    <a class="custom-link" routerLink="/support"><b>Scopri qui come</b></a>
  </div>
</div>
<div class="row no-padding div-custom-link">
  <div class="col">
    <a class="custom-link" routerLink="/home">Home</a>
  </div>
</div>
