<div class="row no-padding custom-link-div sustain-link-div">
  <div class="col">
    <a routerLink="/sustain" class="custom-link"><b>New! Sostieni JustPlay!</b></a>
  </div>
</div>

<div class="row no-padding">
  <div class="col">
    <iframe class="block" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fjustplaybologna&tabs=timeline&width=360px&height=475&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="360px" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
  </div>
</div>

<div class="row no-padding">
  <div class="col mapouter">
    <div class="gmap_canvas">
      <iframe width="360" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=44.573387,%2011.361620&t=&z=18&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
    </div>
  </div>
</div>

<div class="row no-padding">
  <footer class=" col">
    <h3>Contatti</h3>
    <p><a href="mailto:info@justplaybo.it">info@justplaybo.it</a></p>
    <p>Via Lirone 10/c 40013 Castel Maggiore (BO)</p>
  </footer>
</div>
