import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sustain-ad',
  templateUrl: './sustain-ad.component.html',
  styleUrls: ['./sustain-ad.component.less']
})
export class SustainAdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
